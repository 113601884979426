export default {
	"Skyrim": true,
	"[quest]": false,
	"Dawnguard": true,
	"Dragonborn": true,
	"Hearthfire": true,
	"Rare Curios Creation": false,
	"Fishing Creation": false,
	"Saints & Seducers Creation": false,
	"The Cause Creation": false,
	"Plague of the Dead Creation": false,
	"Goblins Creation": false
}